/**
 * item and slot component both use similar wrapper
 * we need to know their size change at any time
 */

import { ComponentInternalInstance, ComponentPublicInstance, computed, defineComponent, getCurrentInstance, onBeforeUnmount, onMounted, onUpdated, Ref, ref } from 'vue';
import { ItemProps, ItemPropsType, SlotProps, VirtualPropsType } from './props';

const useWrapper = (root: Ref<HTMLElement>, props: any) => {
  const shapeKey = computed(() => (props.horizontal ? 'offsetWidth' : 'offsetHeight'));

  let resizeObserver: ResizeObserver;
  let vm: ComponentInternalInstance;

  const getCurrentSize = () => (root.value ? root.value[shapeKey.value] : 0);

  // tell parent current size identify by unqiue key
  const dispatchSizeChange = () => {
    vm?.parent!.emit(props.event, props.uniqueKey, getCurrentSize(), props.hasInitial);
  };

  onMounted(() => {
    if ('ResizeObserver' in window) {
      vm = getCurrentInstance()!;
      resizeObserver = new ResizeObserver(() => {
        dispatchSizeChange();
      });
      resizeObserver.observe(root.value);
    }
  });

  // since componet will be reused, so disptach when updated
  onUpdated(() => {
    dispatchSizeChange();
  });

  onBeforeUnmount(() => {
    if (resizeObserver) {
      resizeObserver.disconnect();
    }
  });
};

// wrapping for item
export const Item = defineComponent({
  name: 'VirtualListItem',
  props: ItemProps,
  setup(props) {
    const root = ref<HTMLElement>(null as any);
    useWrapper(root, props);
    return () => {
      const { index, source, scopedSlots = {}, uniqueKey, slotComponent } = props;
      const Tag = props.tag as any;
      const Component = props.component as any;
      const extraProps = props.extraProps || {};
      const eleProps = {
        ...extraProps,
        source,
        index,
      };

      return <Tag ref={root} key={uniqueKey} role={'listitem'}>
        {slotComponent
          ? <div>{slotComponent({ item: source, index, scope: eleProps })}</div>
          : <Component {...eleProps}>{scopedSlots}</Component>}
      </Tag>;
    };
  },
});

// wrapping for slot
export const Slot = defineComponent({
  name: 'VirtualListSlot',
  props: SlotProps,
  setup(props, ctx) {
    const root = ref<HTMLElement>(null as any);
    useWrapper(root, props);
    return () => {
      const { uniqueKey } = props;
      const Tag = props.tag as any;
      return <Tag ref={root} key={uniqueKey} role={uniqueKey}>{ctx.slots.default}</Tag>;
    };
  },
});
