
import { defineComponent, reactive, ref, computed, onMounted, toRaw, onUnmounted, watch, PropType, Ref } from 'vue';
import { message } from 'ant-design-vue';
import { deepReduce } from '@/utils/illuminate';
import { CloseOutlined } from '@ant-design/icons-vue';
import { TreeDataItem } from 'ant-design-vue/es/tree/Tree';
import { on, off } from '@/utils/eventBus';

import { createApiUrl } from '@/utils/utils';
import { useState } from '@/store';
import { request } from '@/utils/request';
import { DepartmentItem, EVENT_LIST } from '@/utils/constants';
import VirtualList from '@/components/VirtualList/VirtualList';

type StaffType = {
  departmentId: any;
  phone: string;
  userCode: string;
  userName: string;
}

type DepartmentType = {
  defaultFlag: number;
  departmentList: DepartmentType[];
  children: DepartmentType[];
  departmentName: string;
  finiteAmount: string;
  title: string;
  key: number;
  id: number;
  parentIds: number[];
}

const getParentKey = (key: string, tree: TreeDataItem[]): string | number | undefined => {
  let parentKey;
  for (let i = 0; i < tree.length; i++) {
    const node = tree[i];
    if (node.children) {
      if (node.children.some(item => item.key === key)) {
        parentKey = node.key;
      } else if (getParentKey(key, node.children)) {
        parentKey = getParentKey(key, node.children);
      }
    }
  }
  return parentKey;
};

type StaffItemExtraProps = {
  isMultiple: Ref<boolean>;
  selectStaffIds: Ref<string[]>;
  onStaffStateChange: (id: string, state: boolean) => void;
}

const flatedDepartmentList: DepartmentType[] = [];

const settleDepartmentTree = (arr: DepartmentType[], parentIds: number[] = []) => arr.map(item => {
  if (item.departmentList?.length) {
    item.children = settleDepartmentTree(item.departmentList, parentIds.concat(item.id));
  }
  item.key = item.id;
  item.title = item.departmentName;
  item.parentIds = parentIds;
  flatedDepartmentList.push(item);
  return item;
});

const StaffItem = defineComponent({
  name: 'StaffItem',
  props: {
    source: { type: Object as PropType<StaffType>, required: true },
    isMultiple: { type: Object as PropType<Ref<boolean>>, required: true },
    selectStaffIds: { type: Object as PropType<StaffItemExtraProps['selectStaffIds']>, required: true },
    onStaffStateChange: { type: Function as PropType<StaffItemExtraProps['onStaffStateChange']>, required: true },
  },
  setup(props) {
    const isChecked = computed(() => props.selectStaffIds.value.includes(props.source.userCode));
    const onItemChange = () => {
      props.onStaffStateChange(props.source.userCode, !isChecked.value);
    };

    const renderStaffInfo = () => (<p class="staffInfo" title={`${props.source.userName} - ${props.source.phone}`}>
      <span>{props.source.userName}</span>
      <span>- [{props.source.phone}]</span>
    </p>);

    return () => (props.isMultiple.value
      ? <a-checkbox checked={isChecked.value} onChange={onItemChange}>{renderStaffInfo()}</a-checkbox>
      : <a-radio checked={isChecked.value} onClick={onItemChange}>{renderStaffInfo()}</a-radio>);
  },
});

const SelectStaffItem = defineComponent({
  name: 'SelectStaffItem',
  props: {
    source: { type: Object as PropType<StaffType>, required: true },
    onRemoveSelectStaff: { type: Function as PropType<((userCode: string) => void)>, required: true },
  },
  setup(props) {
    return () => <div>
      <span class="name" title={props.source.userName}>{props.source.userName}</span>
      <a-button onClick={() => props.onRemoveSelectStaff(props.source.userCode)}><CloseOutlined /></a-button>
    </div>;
  },
});

export default defineComponent({
  name: 'AddStaffModal',
  components: {
    VirtualList,
  },
  props: {
    mode: { type: String, default: 'multiple' },
    title: { type: String, required: true },
    tips: { type: String, default: '' },
    help: { type: String, default: '' },
    loadStaffList: { type: Function as PropType<(deptId: string | number) => Promise<any>>, required: true },
    submitUserList: { type: Function as PropType<(userCodes: string[]) => Promise<any>>, required: true },
  },
  emits: ['complete'],
  setup(props, ctx) {
    const state = useState();
    let level1DepartmentIds: number[] = [];
    const departmentKeywords = ref('');
    const staffKeywords = ref('');
    const isMultiple = computed(() => props.mode === 'multiple');

    /* = ---------------------------- = 部门树 = ---------------------------- = */
    const departmentList = ref<(DepartmentItem & any)[]>([]);
    const expandedDeptIds = ref<number[]>([]);
    const checkedDeptIds = ref<number[]>([]);
    const selectedDepartmentIds = ref<number[]>([]);
    // 加载企业部门
    const departmentLoaded = false;
    const departmentLoading = ref(false);

    const loadEnterpriseDepartment = async () => {
      let res: any;
      departmentLoading.value = true;
      try {
        res = await request.get(createApiUrl('/newlinkSass/enterprise/find-enterprise-department'));
      } catch (e: Error & any) {
        return message.error(e.message);
      } finally {
        setTimeout(() => {
          departmentLoading.value = false;
        }, 500);
      }

      departmentList.value = settleDepartmentTree(res.data.departmentList);
      level1DepartmentIds = expandedDeptIds.value = res.data.departmentList.map((item: any) => item.id);
    };

    const onExpandDept = (keys: number[]) => {
      expandedDeptIds.value = keys;
    };

    watch(departmentKeywords, value => {
      if (!value) {
        expandedDeptIds.value = level1DepartmentIds;
        return;
      }

      const notUnique = flatedDepartmentList.reduce((pre, item) => {
        if ((item.title as string).indexOf(value) > -1) return pre.concat(item.parentIds);
        return pre;
      }, [] as number[]);

      expandedDeptIds.value = Array.from(new Set(notUnique));
    });

    // = ---------------------------- = 员工列表 = ---------------------------- =
    const staffList = ref<StaffType[]>([]);
    const staffLoading = ref(false);

    const filterStaffList = computed(() => {
      if (!staffKeywords.value) return staffList.value;
      return staffList.value.filter(item => (item.userName?.indexOf(staffKeywords.value) !== -1 || item.phone?.indexOf(staffKeywords.value) !== -1));
    });

    const onChecked = (_: unknown, e: { node: { dataRef: unknown } }, checkedDeptIds: string[], onItemSelect: (i: string, checked: boolean) => void) => {
      const { dataRef } = e.node;
      const list = deepReduce<string[]>([dataRef], 'children', (prev, item) => {
        if (item.departmentName) return prev;
        prev.push(item.key);
        return prev;
      }, []);

      list.forEach(item => {
        onItemSelect(item, checkedDeptIds.indexOf(item) === -1);
      });
    };

    // 加载数据
    const loadData = async () => {
      staffLoading.value = true;
      try {
        staffList.value = await props.loadStaffList(selectedDepartmentIds.value[0] || '');
      } catch (e: Error & any) {
        return message.error(e.message);
      } finally {
        setTimeout(() => {
          staffLoading.value = false;
        }, 500);
      }
    };

    // 点选部门
    const onSelectDept = (keys: number[], e: any) => {
      selectedDepartmentIds.value = keys;
      loadData();
    };

    /* = ---------------------------- = 选中员工 = ---------------------------- = */
    const selectStaffIds = ref<string[]>([]);
    const allSelectStaffIdSet = new Set<string>();
    const allSeletctStaff = ref<StaffType[]>([]);

    watch(filterStaffList, val => {
      selectStaffIds.value = val.filter(i => allSelectStaffIdSet.has(i.userCode)).map(i => i.userCode);
    });

    const resetAllSelectStaff = () => {
      allSeletctStaff.value = staffList.value.filter(i => allSelectStaffIdSet.has(i.userCode));
    };

    const onStaffStateChange = (userCode: string, state: boolean) => {
      if (state && isMultiple.value) {
        allSelectStaffIdSet.add(userCode);
        selectStaffIds.value.push(userCode);
      } else if (state && !isMultiple.value) {
        allSelectStaffIdSet.clear();
        allSelectStaffIdSet.add(userCode);
        selectStaffIds.value = [userCode];
      } else {
        allSelectStaffIdSet.delete(userCode);
        selectStaffIds.value = selectStaffIds.value.filter(i => i !== userCode);
      }

      resetAllSelectStaff();
    };

    const itemExtraProps: StaffItemExtraProps = {
      isMultiple,
      selectStaffIds,
      onStaffStateChange,
    };

    const isPartSelect = computed(() => selectStaffIds.value.length > 0 && selectStaffIds.value.length < filterStaffList.value.length);

    const onChangeSelectAll = () => {
      if (selectStaffIds.value.length < filterStaffList.value.length) {
        selectStaffIds.value = filterStaffList.value.map(item => {
          allSelectStaffIdSet.add(item.userCode);
          return item.userCode;
        });
      } else {
        selectStaffIds.value.map(allSelectStaffIdSet.delete.bind(allSelectStaffIdSet));
        selectStaffIds.value = [];
      }

      resetAllSelectStaff();
    };

    const onRemoveSelectStaff = (userCode: string) => {
      allSelectStaffIdSet.delete(userCode);
      allSeletctStaff.value = allSeletctStaff.value.filter(i => i.userCode !== userCode);
      selectStaffIds.value = selectStaffIds.value.filter(i => i !== userCode);
    };

    // = ---------------------------- = 显示入口 = ---------------------------- =
    const visible = ref<boolean>(false);
    const add = () => {
      staffList.value = [];
      allSelectStaffIdSet.clear();
      selectStaffIds.value = [];
      allSeletctStaff.value = [];
      visible.value = true;
      loadData();
      !departmentLoaded && loadEnterpriseDepartment();
      on(EVENT_LIST.DEPARTMENT_CHANGE, loadEnterpriseDepartment);
    };

    // = ---------------------------- = 提交数据 = ---------------------------- =
    const clean = () => {
      selectedDepartmentIds.value = [];
      staffList.value = [];
      departmentKeywords.value = '';
      staffKeywords.value = '';
    };

    const onSubmit = async () => {
      if (allSelectStaffIdSet.size) {
        try {
          await props.submitUserList(Array.from(allSelectStaffIdSet));
        } catch (e: Error & any) {
          return message.error(e.message);
        }
      }

      if (allSelectStaffIdSet.size) message.success('员工添加完成');

      visible.value = false;
      clean();
      off(EVENT_LIST.DEPARTMENT_CHANGE, loadEnterpriseDepartment);

      ctx.emit('complete');
    };

    onUnmounted(() => {
      off(EVENT_LIST.DEPARTMENT_CHANGE, loadEnterpriseDepartment);
    });

    return {
      staffItemComponent: StaffItem,
      selectStaffComponent: SelectStaffItem,
      visible,
      add,
      checkedDeptIds,
      isMultiple,

      onSelectDept,
      onExpandDept,

      departmentLoading,
      departmentKeywords,
      departmentList,
      expandedDeptIds,
      selectedDepartmentIds,

      staffKeywords,
      selectStaffIds,
      filterStaffList,
      staffLoading,
      itemExtraProps,
      isPartSelect,

      allSeletctStaff,
      onChangeSelectAll,
      onRemoveSelectStaff,

      onSubmit,
      onCancel: clean,
      onChecked,
    };
  },
});
