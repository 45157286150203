/**
 * virtual list default component
 */
import { on } from '@/utils/eventBus';

import { computed, defineComponent, onActivated, onMounted, ref, watch } from 'vue';
import { onUnmounted } from '@vue/runtime-core';
import Virtual, { VirtualListRange } from './virtual';

// eslint-disable-next-line import/named
import { Item, Slot } from './item';
import { VirtualProps } from './props';

const EVENT_TYPE = {
  ITEM: 'item_resize',
  SLOT: 'slot_resize',
};

const SLOT_TYPE = {
  HEADER: 'thead', // string value also use for aria role attribute
  FOOTER: 'tfoot',
};

export default defineComponent({
  name: 'VirtualList',
  props: VirtualProps,
  emits: ['tobottom', 'resized', 'scroll', 'totop'],
  setup(props, ctx) {
    const range = ref<VirtualListRange>(null as any);
    const isHorizontal = computed(() => props.direction === 'horizontal');
    const directionKey = computed(() => (isHorizontal.value ? 'scrollLeft' : 'scrollTop'));

    const getUniqueIdFromDataSources = () => {
      const { dataKey } = props;
      return (props.dataSources as Record<string, any>[]).map(dataSource => (typeof dataKey === 'function' ? dataKey(dataSource) : dataSource[dataKey as string]));
    };

    // here is the rerendering entry
    const onRangeChanged = (rangeObj: VirtualListRange) => {
      range.value = rangeObj;
    };

    let virtual: Virtual | null;
    // ----------- public method end -----------
    const installVirtual = () => {
      virtual = new Virtual({
        slotHeaderSize: 0,
        slotFooterSize: 0,
        keeps: props.keeps,
        estimateSize: props.estimateSize,
        buffer: props.buffer || Math.round(props.keeps / 3), // recommend for a third of keeps
        uniqueIds: getUniqueIdFromDataSources(),
      }, onRangeChanged);

      // sync initial range
      range.value = virtual.getRange();
    };

    installVirtual();

    // event called when each item mounted or size changed
    const onItemResized = (id: number, size: number) => {
      virtual!.saveSize(id, size);
      ctx.emit('resized', id, size);
    };

    // event called when slot mounted or size changed
    const onSlotResized = (type: string, size?: number, hasInit?: boolean) => {
      if (type === SLOT_TYPE.HEADER) {
        virtual!.updateParam('slotHeaderSize', size);
      } else if (type === SLOT_TYPE.FOOTER) {
        virtual!.updateParam('slotFooterSize', size);
      }

      if (hasInit) {
        virtual!.handleSlotSizeChange();
      }
    };

    // get item size by id
    const getSize = (id: number) => virtual!.sizes.get(id);

    // get the total number of stored (rendered) items
    const getSizes = () => virtual!.sizes.size;

    // return client viewport size
    const root = ref<HTMLElement>(null as any);
    const getClientSize = () => {
      const key = isHorizontal.value ? 'clientWidth' : 'clientHeight';
      if (props.pageMode) {
        return document.documentElement[key] || document.body[key];
      }

      return root.value ? Math.ceil(root.value[key]) : 0;
    };

    // return all scroll size
    const getScrollSize = () => {
      const key = isHorizontal.value ? 'scrollWidth' : 'scrollHeight';
      if (props.pageMode) {
        return document.documentElement[key] || document.body[key];
      }
      return root.value ? Math.ceil(root.value[key]) : 0;
    };

    // return current scroll offset
    const getOffset = () => {
      if (props.pageMode) {
        return document.documentElement[directionKey.value] || document.body[directionKey.value];
      }

      return root.value ? Math.ceil(root.value[directionKey.value]) : 0;
    };

    // set current scroll position to a expectant offset
    const scrollToOffset = (offset: number) => {
      if (props.pageMode) {
        document.body[directionKey.value] = offset;
        document.documentElement[directionKey.value] = offset;
      } else if (root.value) {
        root.value[directionKey.value] = offset;
      }
    };

    // set current scroll position to bottom
    const shepherd = ref<HTMLDivElement>(null as any);
    const scrollToBottom = () => {
      if (shepherd.value) {
        const offset = shepherd.value[isHorizontal.value ? 'offsetLeft' : 'offsetTop'];
        scrollToOffset(offset);

        // check if it's really scrolled to the bottom
        // maybe list doesn't render and calculate to last range
        // so we need retry in next event loop until it really at bottom
        setTimeout(() => {
          if (getOffset() + getClientSize() < getScrollSize()) {
            scrollToBottom();
          }
        }, 3);
      }
    };

    // set current scroll position to a expectant index
    const scrollToIndex = (index: number) => {
      // scroll to bottom
      if (index >= props.dataSources!.length - 1) {
        scrollToBottom();
      } else {
        const offset = virtual!.getOffset(index);
        scrollToOffset(offset);
      }
    };

    // when using page mode we need update slot header size manually
    // taking root offset relative to the browser as slot header size
    const updatePageModeFront = () => {
      if (root.value) {
        const rect = root.value.getBoundingClientRect();
        const { defaultView } = root.value.ownerDocument as HTMLDocument;
        const offsetFront = isHorizontal.value ? (rect.left + defaultView!.pageXOffset) : (rect.top + defaultView!.pageYOffset);
        virtual!.updateParam('slotHeaderSize', offsetFront);
      }
    };

    // reset all state back to initial
    const reset = () => {
      virtual = null;
      scrollToOffset(0);
      installVirtual();
    };

    // emit event in special position
    const emitEvent = (offset: number, clientSize: number, scrollSize: number, evt: Event) => {
      ctx.emit('scroll', evt, virtual!.getRange());

      if (virtual!.isFront() && !!props.dataSources!.length && (offset - props.topThreshold <= 0)) {
        ctx.emit('totop');
      } else if (virtual!.isBehind() && (offset + clientSize + props.bottomThreshold >= scrollSize)) {
        ctx.emit('tobottom');
      }
    };

    const onScroll = (evt: Event) => {
      const offset = getOffset();
      const clientSize = getClientSize();
      const scrollSize = getScrollSize();

      // iOS scroll-spring-back behavior will make direction mistake
      if (offset < 0 || (offset + clientSize > scrollSize + 1) || !scrollSize) {
        return;
      }

      virtual!.handleScroll(offset);
      emitEvent(offset, clientSize, scrollSize, evt);
    };

    // get the real render slots based on range data
    // in-place patch strategy will try to reuse components as possible
    // so those components that are reused will not trigger lifecycle mounted
    const getRenderSlots = () => {
      const slots = [];
      const { start, end } = range.value;
      const { dataSources, dataKey, itemClass, itemTag, itemStyle, extraProps, dataComponent, itemScopedSlots } = props;

      const slotComponent = ctx.slots && ctx.slots.item;
      for (let index = start; index <= end; index++) {
        const dataSource = (dataSources as Record<string, any>[])![index];
        if (dataSource) {
          const uniqueKey = typeof dataKey === 'function' ? dataKey(dataSource) : dataSource[dataKey as string];
          if (typeof uniqueKey === 'string' || typeof uniqueKey === 'number') {
            const itemProps = {
              tag: itemTag,
              event: EVENT_TYPE.ITEM,
              horizontal: isHorizontal.value,
              source: dataSource,
              component: dataComponent,
              scopedSlots: itemScopedSlots,
              key: uniqueKey,
              uniqueKey,
              index,
              slotComponent,
              extraProps,
            };
            slots.push(<Item style={itemStyle} class={`${itemClass}${props.itemClassAdd ? ` ${props.itemClassAdd(index)}` : ''}`} {...itemProps} />);
          } else {
            console.warn(`Cannot get the data-key '${dataKey}' from data-sources.`);
          }
        } else {
          console.warn(`Cannot get the index '${index}' from data-sources.`);
        }
      }
      return slots;
    };

    // listen item size change
    on(EVENT_TYPE.ITEM, onItemResized as any);

    // listen slot size change
    if (ctx.slots.header || ctx.slots.footer) {
      on(EVENT_TYPE.SLOT, onSlotResized as any);
    }

    watch(() => props.dataSources!.length, () => {
      virtual!.updateParam('uniqueIds', getUniqueIdFromDataSources());
      virtual!.handleDataSourcesChange();
    });

    watch(() => props.keeps, newValue => {
      virtual!.updateParam('keeps', newValue);
      virtual!.handleSlotSizeChange();
    });

    watch(() => props.start, newValue => {
      scrollToIndex(newValue);
    });

    watch(() => props.offset, newValue => {
      scrollToOffset(newValue);
    });

    onMounted(() => {
      // set position
      if (props.start) {
        scrollToIndex(props.start);
      } else if (props.offset) {
        scrollToOffset(props.offset);
      }

      // in page mode we bind scroll event to document
      if (props.pageMode) {
        updatePageModeFront();

        document.addEventListener('scroll', onScroll, {
          passive: false,
        });
      }
    });

    // set back offset when awake from keep-alive
    onActivated(() => {
      scrollToOffset(virtual!.offset);
    });

    onUnmounted(() => {
      virtual = null;
      if (props.pageMode) {
        document.removeEventListener('scroll', onScroll);
      }
    });

    // render function, a closer-to-the-compiler alternative to templates
    return () => {
      const { header, footer } = ctx.slots;
      const { padFront, padBehind } = range.value;
      const { pageMode, wrapClass, wrapStyle, headerTag, headerClass, headerStyle, footerTag, footerClass, footerStyle } = props;
      const RootTag = props.rootTag as any;
      const WrapTag = props.wrapTag as any;
      const paddingStyle = { padding: isHorizontal.value ? `0px ${padBehind}px 0px ${padFront}px` : `${padFront}px 0px ${padBehind}px` };
      const wrapperStyle = wrapStyle ? ({ ...wrapStyle, ...paddingStyle }) : paddingStyle;

      return <RootTag ref={root} onScroll={!pageMode && onScroll}>
        {/* header slot */}
        {header ? <Slot class={headerClass} style={headerStyle} tag={headerTag} event={EVENT_TYPE.SLOT} uniqueKey={SLOT_TYPE.HEADER}>{header}</Slot> : null}
        {/* main list */}
        <WrapTag class={wrapClass} style={wrapperStyle} role={'group'}>{getRenderSlots()}</WrapTag>
        {/* footer slot */}
        {footer ? <Slot class={footerClass} style={footerStyle} tag={footerTag} event={EVENT_TYPE.SLOT} uniqueKey={SLOT_TYPE.FOOTER}>{footer}</Slot> : null}
        {/* an empty element use to scroll to bottom */}
        <div ref={shepherd} style={{ width: isHorizontal.value ? '0px' : '100%', height: isHorizontal.value ? '100%' : '0px' }} />
      </RootTag>;
    };
  },
});
