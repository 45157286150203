/**
 * props declaration for default, item and slot component
 */

declare type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends ((k: infer I) => void) ? I : never;

export const VirtualProps = {
  dataKey: { type: [String, Function], required: true },
  dataSources: { type: Array, required: true },
  dataComponent: { type: [Object, Function], required: true },
  keeps: { type: Number, default: 30 },
  buffer: { type: Number, default: 1 },
  extraProps: { type: Object },
  estimateSize: { type: Number, default: 50 },
  // the other value is horizontal
  direction: { type: String, default: 'vertical' },
  start: { type: Number, default: 0 },
  offset: { type: Number, default: 0 },
  topThreshold: { type: Number, default: 0 },
  bottomThreshold: { type: Number, default: 0 },
  pageMode: { type: Boolean, default: false },
  rootTag: { type: String, default: 'div' },
  wrapTag: { type: String, default: 'div' },
  wrapClass: { type: String, default: '' },
  wrapStyle: { type: Object },
  itemTag: { type: String, default: 'div' },
  itemClass: { type: String, default: '' },
  itemClassAdd: { type: Function },
  itemStyle: { type: Object },
  headerTag: { type: String, default: 'div' },
  headerClass: { type: String, default: '' },
  headerStyle: { type: Object },
  footerTag: { type: String, default: 'div' },
  footerClass: { type: String, default: '' },
  footerStyle: { type: Object },
  itemScopedSlots: { type: Object },
};

export type VirtualPropsType = UnionToIntersection<typeof VirtualProps>;

export const ItemProps = {
  index: { type: Number },
  event: { type: String },
  tag: { type: String },
  horizontal: { type: Boolean },
  source: { type: Object },
  component: { type: [Object, Function] },
  slotComponent: { type: Function },
  uniqueKey: { type: [String, Number] },
  extraProps: { type: Object },
  scopedSlots: { type: Object },
};

export type ItemPropsType = UnionToIntersection<typeof ItemProps>;

export const SlotProps = {
  event: { type: String },
  uniqueKey: { type: String },
  tag: { type: String },
  horizontal: { type: Boolean },
};

export type SlotPropsType = UnionToIntersection<typeof SlotProps>;
