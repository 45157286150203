import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "addStaffModal__body" }
const _hoisted_2 = { class: "addStaffModal__content" }
const _hoisted_3 = { class: "addStaffModal__department" }
const _hoisted_4 = { class: "searchWrap" }
const _hoisted_5 = { class: "addStaffModal__departmentWrap" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { style: {"color":"#008dff"} }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { class: "addStaffModal__staffListWrap" }
const _hoisted_10 = { class: "searchWrap" }
const _hoisted_11 = {
  key: 0,
  class: "selectControl"
}
const _hoisted_12 = { class: "text-info" }
const _hoisted_13 = { class: "addStaffModal__checkedStaffWrap" }
const _hoisted_14 = { class: "desc" }
const _hoisted_15 = {
  key: 0,
  class: "discretion"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_tree = _resolveComponent("a-tree")!
  const _component_a_spin = _resolveComponent("a-spin")!
  const _component_a_checkbox = _resolveComponent("a-checkbox")!
  const _component_virtual_list = _resolveComponent("virtual-list")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createBlock(_component_a_modal, {
    visible: _ctx.visible,
    "onUpdate:visible": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.visible) = $event)),
    class: "addStaffModal",
    width: "950px",
    height: "800px",
    title: "添加员工",
    "destroy-on-close": "",
    onOk: _ctx.onSubmit,
    onCancel: _ctx.onCancel
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_a_input, {
                value: _ctx.departmentKeywords,
                "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.departmentKeywords) = $event)),
                placeholder: "部门名称",
                "allow-clear": ""
              }, null, 8, ["value"])
            ]),
            _createVNode(_component_a_spin, { spinning: _ctx.departmentLoading }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_5, [
                  _createVNode(_component_a_tree, {
                    "checked-keys": _ctx.checkedDeptIds,
                    "onUpdate:checkedKeys": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.checkedDeptIds) = $event)),
                    "show-icon": false,
                    "show-line": true,
                    "auto-expand-parent": false,
                    "expanded-keys": _ctx.expandedDeptIds,
                    "selected-keys": _ctx.selectedDepartmentIds,
                    "tree-data": _ctx.departmentList,
                    onSelect: _ctx.onSelectDept,
                    onExpand: _ctx.onExpandDept
                  }, {
                    title: _withCtx(({ title }) => [
                      (title.indexOf(_ctx.departmentKeywords) > -1)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_6, [
                            _createTextVNode(_toDisplayString(title.substr(0, title.indexOf(_ctx.departmentKeywords))) + " ", 1),
                            _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.departmentKeywords), 1),
                            _createTextVNode(" " + _toDisplayString(title.substr(title.indexOf(_ctx.departmentKeywords) + _ctx.departmentKeywords.length)), 1)
                          ]))
                        : (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(title), 1))
                    ]),
                    _: 1
                  }, 8, ["checked-keys", "expanded-keys", "selected-keys", "tree-data", "onSelect", "onExpand"])
                ])
              ]),
              _: 1
            }, 8, ["spinning"])
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              _createVNode(_component_a_input, {
                value: _ctx.staffKeywords,
                "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.staffKeywords) = $event)),
                placeholder: "员工姓名/手机号码",
                "allow-clear": ""
              }, null, 8, ["value"])
            ]),
            (_ctx.isMultiple)
              ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                  _createVNode(_component_a_checkbox, {
                    checked: _ctx.selectStaffIds.length > 0,
                    style: {"width":"100px"},
                    indeterminate: _ctx.isPartSelect,
                    onChange: _ctx.onChangeSelectAll
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("全选")
                    ]),
                    _: 1
                  }, 8, ["checked", "indeterminate", "onChange"]),
                  _createElementVNode("p", null, [
                    _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.selectStaffIds.length), 1),
                    _createTextVNode(" / " + _toDisplayString(_ctx.filterStaffList.length), 1)
                  ])
                ]))
              : _createCommentVNode("", true),
            _createVNode(_component_a_spin, { spinning: _ctx.staffLoading }, {
              default: _withCtx(() => [
                _createVNode(_component_virtual_list, {
                  class: "staffList",
                  "item-class": "staffItem",
                  "data-key": "userCode",
                  "data-sources": _ctx.filterStaffList,
                  "data-component": _ctx.staffItemComponent,
                  "estimate-size": 38,
                  "extra-props": _ctx.itemExtraProps
                }, null, 8, ["data-sources", "data-component", "extra-props"])
              ]),
              _: 1
            }, 8, ["spinning"])
          ]),
          _createElementVNode("div", _hoisted_13, [
            _createVNode(_component_virtual_list, {
              class: "staffList",
              "item-class": "staffItem",
              "data-key": "userCode",
              keeps: 90,
              buffer: 45,
              "data-sources": _ctx.allSeletctStaff,
              "data-component": _ctx.selectStaffComponent,
              "estimate-size": 12,
              "extra-props": { onRemoveSelectStaff: _ctx.onRemoveSelectStaff }
            }, null, 8, ["data-sources", "data-component", "extra-props"]),
            _createElementVNode("div", _hoisted_14, "已选 " + _toDisplayString(_ctx.allSeletctStaff.length) + " 人", 1)
          ])
        ]),
        (_ctx.tips)
          ? (_openBlock(), _createElementBlock("p", _hoisted_15, _toDisplayString(_ctx.tips), 1))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["visible", "onOk", "onCancel"]))
}